(function (root, factory) {
        if (typeof define === 'function' && define.amd) {
            // AMD. Register as an anonymous module.
            define([], factory);
        } else if (typeof module === 'object' && module.exports) {
            // Node. Does not work with strict CommonJS, but
            // only CommonJS-like environments that support module.exports,
            // like Node.
            module.exports = factory();
        } else {
            // Browser globals (root is window)
            root.returnExports = factory();
        }
    }(typeof self !== 'undefined' ? self : this, function () {
        // Just return a value to define the module export.
        // This example returns an object, but the module
        // can return a function as the exported value.
        'use strict';
        var skins = {};
         skins['wysiwyg.viewer.skins.appinputs.AppsTextAreaInputSkin'] = {
  "react": [
    [
      "label",
      "label",
      [],
      {}
    ],
    [
      "textarea",
      "textarea",
      [],
      {}
    ],
    [
      "p",
      "errorMessage",
      [],
      {}
    ],
    [
      "div",
      null,
      [
        "_err"
      ],
      {}
    ]
  ],
  "params": {
    "fntlbl": "FONT",
    "txtlbl": "TEXT_COLOR"
  },
  "paramsDefaults": {
    "fntlbl": "font_8",
    "txtlbl": "color_15"
  },
  "css": {
    "%": "position:relative;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;",
    "%textarea": "resize:none;width:100% !important;-webkit-box-flex:1;-webkit-flex:1;flex:1;overflow:auto;",
    "% p": "display:none;position:absolute;",
    "%[data-state~=\"invalid\"] %textarea": "color:#d00;border-color:#d00;",
    "% textarea:focus": "color:#444  !important;border-color:#000 !important;",
    "%_err": "position:absolute;top:50%;left:0;height:1px;width:1px;",
    "%[data-state~=\"invalid\"] %_err:before": "color:#fff;background:#d00;border:2px solid #fff;border-radius:50%;box-shadow:0 1px 3px rgba(0, 0, 0, 0.5);text-align:center;font-size:12px;content:\"!\";position:absolute;top:5px;left:-15px;width:20px;height:20px;line-height:20px !important;",
    "%[data-state~=\"invalid\"] %_err:after": "color:#fff;font-size:10px;text-shadow:1px 1px 3px rgba(0, 0, 0, 0.5);content:\"►\";position:absolute;top:10px;left:8px;",
    "%label": "[fntlbl]  color:[txtlbl];word-break:break-word;display:inline-block;line-height:1;"
  }
}
 skins['wysiwyg.viewer.skins.appinputs.AppsTextAreaInputSkinNoValidation'] = {
  "react": [
    [
      "label",
      "label",
      [],
      {}
    ],
    [
      "textarea",
      "textarea",
      [],
      {}
    ],
    [
      "p",
      "errorMessage",
      [],
      {}
    ],
    [
      "div",
      null,
      [
        "_err"
      ],
      {}
    ]
  ],
  "params": {
    "fntlbl": "FONT",
    "txtlbl": "TEXT_COLOR"
  },
  "paramsDefaults": {
    "fntlbl": "font_8",
    "txtlbl": "color_15"
  },
  "css": {
    "%": "position:relative;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;",
    "%textarea": "resize:none;width:100% !important;-webkit-box-flex:1;-webkit-flex:1;flex:1;overflow:auto;",
    "% p": "display:none;position:absolute;",
    "%[data-state~=\"invalid\"] %textarea": "color:#d00;border-color:#d00;",
    "% textarea:focus": "color:#444  !important;border-color:#000 !important;",
    "%_err": "position:absolute;top:50%;left:0;height:1px;width:1px;",
    "%[data-state~=\"invalid\"] %_err:before": "color:#fff;background:#d00;border:2px solid #fff;border-radius:50%;box-shadow:0 1px 3px rgba(0, 0, 0, 0.5);text-align:center;font-size:12px;content:\"!\";position:absolute;top:5px;left:-15px;width:20px;height:20px;line-height:20px !important;",
    "%[data-state~=\"invalid\"] %_err:after": "color:#fff;font-size:10px;text-shadow:1px 1px 3px rgba(0, 0, 0, 0.5);content:\"►\";position:absolute;top:10px;left:8px;",
    "%label": "[fntlbl]  color:[txtlbl];word-break:break-word;display:inline-block;line-height:1;"
  }
}
 skins['wysiwyg.viewer.skins.input.ResponsiveTextAreaDefaultSkin'] = {
  "react": [
    [
      "textarea",
      "textarea",
      [],
      {}
    ]
  ],
  "params": {
    "shd": "BOX_SHADOW",
    "rd": "BORDER_RADIUS",
    "fnt": "FONT",
    "brw": "BORDER_SIZES",
    "bg": "BG_COLOR_ALPHA",
    "txt": "TEXT_COLOR",
    "brd": "BORDER_COLOR_ALPHA",
    "txt2": "TEXT_COLOR",
    "brwh": "BORDER_SIZES",
    "bgh": "BG_COLOR_ALPHA",
    "brdh": "BORDER_COLOR_ALPHA",
    "bgd": "BG_COLOR_ALPHA",
    "txtd": "TEXT_COLOR",
    "brwd": "BORDER_SIZES",
    "brdd": "BORDER_COLOR_ALPHA",
    "brwf": "BORDER_SIZES",
    "bgf": "BG_COLOR_ALPHA",
    "brdf": "BORDER_COLOR_ALPHA",
    "brwe": "BORDER_SIZES",
    "bge": "BG_COLOR_ALPHA",
    "brde": "BORDER_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "shd": "0 0 0 rgba(0, 0, 0, 0)",
    "rd": "0",
    "fnt": "font_8",
    "brw": "1px",
    "bg": "#ffffff",
    "txt": "color_15",
    "brd": "#e3e3e3",
    "txt2": "color_15",
    "brwh": "1px",
    "bgh": "#ffffff",
    "brdh": "#a3d9f6",
    "bgd": "#CCCCCC",
    "txtd": "#FFFFFF",
    "brwd": "1px",
    "brdd": "#a3d9f6",
    "brwf": "1px",
    "bgf": "#ffffff",
    "brdf": "#a3d9f6",
    "brwe": "1px",
    "bge": "#ffffff",
    "brde": "#a3d9f6"
  },
  "css": {
    "%textarea": "[shd]  [rd]  [fnt]  [brw]  resize:none;background-color:[bg];box-sizing:border-box !important;color:[txt];border-style:solid;border-color:[brd];padding:3px;margin:0;padding-top:0.75em;overflow-y:auto;width:100%;height:100%;",
    "%textarea::-webkit-input-placeholder": "color:[txt2];",
    "%textarea::-ms-input-placeholder": "color:[txt2];",
    "%textarea::placeholder": "color:[txt2];",
    "%textarea:hover,%textarea[data-preview~=\"hover\"]": "[brwh]    background-color:[bgh];border-style:solid;border-color:[brdh];",
    "%_left-direction %textarea": "text-align:left;",
    "%_right-direction %textarea": "text-align:right;direction:rtl;",
    "%_center-direction %textarea": "text-align:center;",
    "%[data-disabled=\"true\"] %textarea,%[data-preview~=\"disabled\"] %textarea": "background-color:[bgd];color:[txtd];[brwd]  border-style:solid;border-color:[brdd];",
    ":not(%_with-validation-indication) %textarea:focus,:not(%_with-validation-indication) %textarea[data-preview~=\"focus\"]": "[brwf]  background-color:[bgf];border-style:solid;border-color:[brdf];",
    "%_with-validation-indication %textarea:invalid": "[brwe]  background-color:[bge];border-style:solid;border-color:[brde];",
    "%_with-validation-indication %textarea:not(:invalid):focus,%_with-validation-indication %textarea[data-preview~=\"focus\"]": "[brwf]  background-color:[bgf];border-style:solid;border-color:[brdf];",
    "%[data-error=\"true\"] %textarea,%[data-preview~=\"error\"] %textarea": "[brwe]  background-color:[bge];border-style:solid;border-color:[brde];"
  }
}

        return skins;
    }));